import React, { useState, useEffect } from "react";
import PromoBanner from "./components/PromoBanner";
import Navbar from "./components/Navbar";

import logoImg from "./images/logo.png";

import heroImg from "./images/hero-img.png";

import sharkGlove from "./images/shark-glove.png";
import gregNormanPolo from "./images/greg-norman-polo.png";
import gforeGlove from "./images/gfore-glove.png";
import utilityBag from "./images/utility-bag.png";
import airJordanShoes from "./images/air-jordan-shoes.png";

import golfClub from "./images/golf-club.png";
import golfBall from "./images/golf-ball.png";
import golfBag from "./images/golf-bag.png";

import travisJordan from "./images/travis-jordan.png";
import timelessShoe from "./images/timeless-shoe.png";
import ryderCup from "./images/ryder-cup.png";

import promoImg from "./images/promo-img.png";

import juniorSet from "./images/juniors-set.png";
import menSet from "./images/mens-set.png";
import womenSet from "./images/womens-set.png";

import instagramPic1 from "./images/insta-1.jpg";
import instagramPic2 from "./images/insta-2.jpg";
import instagramPic3 from "./images/insta-3.jpg";
import instagramPic4 from "./images/insta-4.jpg";
import instagramPic5 from "./images/insta-5.jpg";
import instagramPic6 from "./images/insta-6.jpg";

import visaPay from "./images/visa-pay.png";
import aePay from "./images/ae-pay.png";
import mastercardPay from "./images/mastercard-pay.png";
import stripePay from "./images/stripe-pay.png";
import paypalPay from "./images/paypal-pay.png";
import applePay from "./images/apple-pay.png";

import {
  BiRightArrowAlt,
  BiEnvelope,
  BiLogoInstagram,
  BiLogoFacebook,
  BiLogoYoutube,
} from "react-icons/bi";

import "./Style.css";

function Home() {
  const categories = [
    { id: 1, name: "Golf Clubs", image: golfClub },
    { id: 2, name: "Golf Balls", image: golfBall },
    { id: 3, name: "Golf Bags", image: golfBag },
    { id: 4, name: "Clothing & Rainwear", image: gregNormanPolo },
    { id: 5, name: "Footwear", image: airJordanShoes },
    { id: 6, name: "Accessories", image: gforeGlove },
  ];

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetTime = new Date();
    targetTime.setDate(targetTime.getDate() + 2);

    const interval = setInterval(() => {
      const now = new Date();
      const diff = targetTime - now;

      if (diff <= 0) {
        clearInterval(interval);
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      } else {
        setTimeLeft({
          days: Math.floor(diff / (1000 * 60 * 60 * 24)),
          hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((diff / (1000 * 60)) % 60),
          seconds: Math.floor((diff / 1000) % 60),
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const collections = [
    { id: 1, name: "Juniors Set", image: juniorSet },
    { id: 2, name: "Men’s Set", image: menSet },
    { id: 3, name: "Women’s Set", image: womenSet },
  ];

  const articles = [
    {
      id: 1,
      name: "Air Jordan x Travis Scott Event",
      image: travisJordan,
      link: "/articles/air-jordan-travis-scott",
    },
    {
      id: 2,
      name: "The timeless classics on the green",
      image: timelessShoe,
      link: "/articles/timeless-classics",
    },
    {
      id: 3,
      name: "The 2023 Ryder Cup",
      image: ryderCup,
      link: "/articles/ryder-cup-2023",
    },
  ];

  return (
    <div className="home">
      {/* Promo Banner */}
      <PromoBanner />
      {/* Navbar */}
      <Navbar />
      {/* Hero Section */}
      <div className="hero d-flex justify-content-between align-items-center">
        <div className="hero-text">
          <h2>More than just a game. It’s a lifestyle.</h2>
          <p>
            Whether you’re just starting out, have played your whole life or
            you're a Tour pro, your swing is like a fingerprint.
          </p>
          <button className="hero-button">Shopping Now</button>
        </div>

        {/* <div className="hero-img">
          <img src={heroImg} alt="hero img" />
        </div> */}
      </div>
      {/* Featured Section */}
      <div className="section featured-section container">
        <div className="title">
          <h3 className="mb-4">Featured</h3>
        </div>
        <div className="row">
          {[
            {
              image: sharkGlove,
              title: "Shark - Men's cabretta white golf glove",
              price: "$19.00",
              hot: true,
            },
            {
              image: gregNormanPolo,
              title: "Greg Norman - Men's Shark Logo Golf Polo Shirt",
              price: "$24.99",
              originalPrice: "$40.00",
              hot: true,
              discount: true,
            },
            {
              image: gforeGlove,
              title: "G/FORE - Mens Left Glove Snow 2023",
              price: "$30.00",
              hot: true,
            },
            {
              image: utilityBag,
              title: "Utility Rover-R Double Strap Bag All Black - 2023",
              price: "$209.99",
              hot: true,
            },
            // {
            //   image: airJordanShoes,
            //   title: "Air Jordan 1 Low OG Iron Grey - SS23",
            //   price: "$111.99",
            //   originalPrice: "$200.00",
            //   hot: true,
            //   discount: true,
            // },
          ].map((item, index) => (
            <div className="col-md-3 mb-4" key={index}>
              <div className="featured-item card h-100 position-relative">
                {item.hot && (
                  <span className="badge bg-white text-black position-absolute top-0 start-0 m-3">
                    HOT
                  </span>
                )}
                {item.discount && (
                  <span
                    className="badge position-absolute top-0 start-0 m-3 mt-5"
                    style={{ backgroundColor: "#38CB89" }}
                  >
                    -50%
                  </span>
                )}
                <div className="card-img-con">
                  <img
                    src={item.image}
                    className="card-img-top"
                    alt={item.title}
                  />
                </div>
                <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text">
                    {item.originalPrice ? (
                      <>
                        <span className="text-success fw-bold">
                          {item.price}
                        </span>{" "}
                        <span className="text-muted text-decoration-line-through">
                          {item.originalPrice}
                        </span>
                      </>
                    ) : (
                      <span>{item.price}</span>
                    )}
                  </p>
                  <button className="btn btn-primary add-to-cart">
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Categories section */}
      <div className="section categories-section container">
        <div className="title text-center">
          <h3 className="mb-4">Shop by Categories</h3>
        </div>

        <div className="row">
          {categories.map((category) => (
            <div className="category col-md-4 mb-5" key={category.id}>
              <div className="img-con">
                <img src={category.image} alt={`${category.name} image`} />
              </div>
              <div className="name text-center">
                <h5>{category.name}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Countdown section */}
      <div
        className="countdown-banner my-5 d-flex justify-content-between flex-wrap"
        style={{ backgroundColor: "#000" }}
      >
        <div className="col-md-6 countdown-image">
          <img src={promoImg} alt="Promo Item" />
        </div>
        <div className="col-md-6 countdown-text">
          <h6>LIMITED EDITION</h6>
          <h2>Hurry up! 30% OFF</h2>
          <p style={{ fontSize: "20px" }}>
            Find clubs that are right for your game
          </p>
          <p style={{ fontSize: "16px" }}>Offer expires in:</p>
          <div className="timer d-flex gap-2">
            <div className="time-box text-center">
              <h4>{String(timeLeft.days).padStart(2, "0")}</h4>
            </div>
            <div className="time-box text-center">
              <h4>{String(timeLeft.hours).padStart(2, "0")}</h4>
            </div>
            <div className="time-box text-center">
              <h4>{String(timeLeft.minutes).padStart(2, "0")}</h4>
            </div>
            <div className="time-box text-center">
              <h4>{String(timeLeft.seconds).padStart(2, "0")}</h4>
            </div>
          </div>
          <button className="btn mt-5">Shop now</button>
        </div>
      </div>
      {/* Shop collection section */}
      <div className="section shop-collection container">
        <h3 className="mb-4">Shop Collection</h3>
        <div className="row g-4">
          <div className="col-md-6">
            <div className="card h-100">
              <img
                src={collections[0].image}
                className="card-img-top w-100"
                alt={collections[0].name}
              />
              <div className="card-body">
                <h5>{collections[0].name}</h5>
                <a href="#" className="btn btn-link">
                  Collections <BiRightArrowAlt />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {/* Men's Set */}
            <div className="row g-4">
              <div className="col-12">
                <div
                  className="card col-md-9 col-12 d-flex justify-content-between align-items-center flex-row
"
                >
                  <div className="card-body">
                    <h5>{collections[1].name}</h5>
                    <a href="#" className="btn btn-link">
                      Collections <BiRightArrowAlt />
                    </a>
                  </div>
                  <img
                    src={collections[1].image}
                    className="card-img-top"
                    alt={collections[1].name}
                  />
                </div>
              </div>
              {/* Women's Set */}
              <div className="col-12">
                <div
                  className="card col-md-9 col-12 d-flex justify-content-between align-items-center flex-row
"
                >
                  <div className="card-body">
                    <h5>{collections[2].name}</h5>
                    <a href="#" className="btn btn-link">
                      Collections <BiRightArrowAlt />
                    </a>
                  </div>
                  <img
                    src={collections[2].image}
                    className="card-img-top"
                    alt={collections[2].name}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Latest articles section */}
      <div className="section articles-section container">
        <div className="title d-flex justify-content-between align-items-center">
          <h3 className="mb-4">Latest Articles</h3>

          <div className="more">
            <a href="" className="ms-2">
              View More <BiRightArrowAlt />
            </a>
          </div>
        </div>

        <div className="row">
          {articles.map((article) => (
            <div className="article col-md-4 mb-5" key={article.id}>
              <div className="img-con">
                <img
                  src={article.image}
                  alt={`${article.name} image`}
                  className="w-100"
                />
              </div>
              <div className="name mt-3">
                <h5>{article.name}</h5>
                <a href={article.link} className="btn btn-link mt-2">
                  Read More <BiRightArrowAlt />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Newsletter Section */}
      <div className="newsletter-section">
        <div className="newsletter-content">
          <h3>Join Our Newsletter</h3>
          <p>Sign up for deals, new products and promotions</p>
          <form className="newsletter-form">
            <div className="input-wrapper" style={{ position: "relative" }}>
              <BiEnvelope
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  fontSize: "16px",
                  transform: "translateY(-50%)",
                  color: "#fefefe",
                }}
              />
              <input
                type="email"
                placeholder="Email address"
                className="newsletter-input"
                style={{ paddingLeft: "40px" }}
              />
            </div>
            <button type="submit" className="newsletter-button">
              Signup
            </button>
          </form>
        </div>
      </div>

      {/* Instagram Feed Section */}
      <div className="instagram-section">
        <h4 className="text-center mt-5">NEWSFEED</h4>
        <h3 className="text-center mb-3">Instagram</h3>
        <p className="text-center">
          Follow us on social media for more discounts & promotions
        </p>
        <p className="text-center instagram-handle">@3legant_official</p>
        <div className="instagram-images d-flex flex-wrap">
          {[
            instagramPic1,
            instagramPic2,
            instagramPic3,
            instagramPic4,
            instagramPic5,
            instagramPic6,
          ].map((image, index) => (
            <div key={index} className="instagram-image">
              <img src={image} alt={`Instagram Post ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      {/* Footer Section */}
      <footer className="footer-section">
        <div className="container">
          <div className="footer-top row">
            <div className="col-md-3 footer-branding mt-4">
              <img src={logoImg} alt="logo" className="mb-4" />
              <p>
                More than just a game. <br /> It’s a lifestyle.
              </p>
              <div className="footer-socials mt-4">
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BiLogoInstagram size={30} />
                </a>
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BiLogoFacebook size={30} />
                </a>
                <a
                  href="https://youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BiLogoYoutube size={30} />
                </a>
              </div>
            </div>
            <div className="col-md-3 footer-links mt-4">
              <h5>Page</h5>
              <ul>
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a href="#shop">Shop</a>
                </li>
                <li>
                  <a href="#product">Product</a>
                </li>
                <li>
                  <a href="#articles">Articles</a>
                </li>
                <li>
                  <a href="#contact">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 footer-links mt-4">
              <h5>Info</h5>
              <ul>
                <li>
                  <a href="#shipping-policy">Shipping Policy</a>
                </li>
                <li>
                  <a href="#return-refund">Return & Refund</a>
                </li>
                <li>
                  <a href="#support">Support</a>
                </li>
                <li>
                  <a href="#faqs">FAQs</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 footer-links mt-4">
              <h5>Office</h5>
              <p>4311 Hai Trieu Street, District 1, HCMC, Vietnam</p>
              <p>847-756-2237</p>
            </div>
          </div>
          <div className="footer-bottom d-flex justify-content-between align-items-center">
            <p>
              Copyright © 2023 3legant. All rights reserved{" "}
              <span>
                <a href="#privacy-policy"> | &nbsp; Privacy Policy</a>
                <a href="#terms-conditions"> Terms & Conditions</a>
              </span>
            </p>
            <div className="footer-payment-icons">
              <img src={visaPay} alt="visa" className="mb-4" />
              <img src={aePay} alt="american express" className="mb-4" />
              <img src={mastercardPay} alt="mastercard" className="mb-4" />
              <img src={stripePay} alt="stripe" className="mb-4" />
              <img src={paypalPay} alt="paypal" className="mb-4" />
              <img src={applePay} alt="applepay" className="mb-4" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
