import React, { useState } from "react";
import { BiSearch, BiUser, BiMenu, BiShoppingBag } from "react-icons/bi";
import "./Navbar.css";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShopDropdownOpen, setIsShopDropdownOpen] = useState(false);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);

  return (
    <nav className="navbar">
      {/* Left Section */}
      <div className="navbar-left">
        <BiMenu
          className="menu-icon"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        <h1 className="logo">3legant.</h1>
      </div>

      {/* Main Navigation */}
      <ul className={`nav-links ${isMenuOpen ? "open" : ""}`}>
        <li>
          <a href="#home">Home</a>
        </li>
        <li
          className="dropdown"
          onMouseEnter={() => setIsShopDropdownOpen(true)}
          onMouseLeave={() => setIsShopDropdownOpen(false)}
        >
          <a href="#shop">Shop</a>
          {isShopDropdownOpen && (
            <ul className="dropdown-menu">
              <li>
                <a href="#mens">Men's Clothing</a>
              </li>
              <li>
                <a href="#womens">Women's Clothing</a>
              </li>
              <li>
                <a href="#accessories">Accessories</a>
              </li>
            </ul>
          )}
        </li>
        <li
          className="dropdown"
          onMouseEnter={() => setIsProductDropdownOpen(true)}
          onMouseLeave={() => setIsProductDropdownOpen(false)}
        >
          <a href="#product">Product</a>
          {isProductDropdownOpen && (
            <ul className="dropdown-menu">
              <li>
                <a href="#new-arrivals">New Arrivals</a>
              </li>
              <li>
                <a href="#best-sellers">Best Sellers</a>
              </li>
              <li>
                <a href="#sale">On Sale</a>
              </li>
            </ul>
          )}
        </li>
        <li>
          <a href="#contact">Contact Us</a>
        </li>
      </ul>

      {/* Right Section */}
      <div className="navbar-right">
        <div className="nav-icons">
          <BiSearch className="icon" />
          <BiUser className="icon" />
          <div className="cart-icon">
            <BiShoppingBag />
            <span className="cart-count">2</span>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <ul className="mobile-nav-links">
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <div
              className="mobile-dropdown"
              onClick={() => setIsShopDropdownOpen(!isShopDropdownOpen)}
            >
              <a href="#shop">Shop</a>
              {isShopDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <a href="#mens">Men's Clothing</a>
                  </li>
                  <li>
                    <a href="#womens">Women's Clothing</a>
                  </li>
                  <li>
                    <a href="#accessories">Accessories</a>
                  </li>
                </ul>
              )}
            </div>
          </li>
          <li>
            <div
              className="mobile-dropdown"
              onClick={() => setIsProductDropdownOpen(!isProductDropdownOpen)}
            >
              <a href="#product">Product</a>
              {isProductDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <a href="#new-arrivals">New Arrivals</a>
                  </li>
                  <li>
                    <a href="#best-sellers">Best Sellers</a>
                  </li>
                  <li>
                    <a href="#sale">On Sale</a>
                  </li>
                </ul>
              )}
            </div>
          </li>
          <li>
            <a href="#contact">Contact Us</a>
          </li>
          <div className="mobile-nav-icons">
            <BiSearch />
            <BiUser />
          </div>
        </ul>
      )}
    </nav>
  );
}

export default Navbar;
