import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./Auth.css";
import authImage from "../images/auth-image2.png";

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container fluid className="signup-container">
      <Row className="min-vh-100 m-0">
        {/* Image Container */}
        <Col
          lg={7}
          className="d-lg-flex justify-content-center image-container"
        >
          <img
            src={authImage}
            alt="auth-img"
            className="auth-image img-fluid"
          />
        </Col>

        {/* Form Container */}
        <Col lg={5} xs={12} className="form-container">
          <h1 className="mb-3">Sign up</h1>
          <p>
            Already have an account? <a href="/signin">Sign in</a>
          </p>
          <Form>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Control type="text" placeholder="Your name" />
            </Form.Group>
            <Form.Group controlId="formUsername" className="mb-3">
              <Form.Control type="text" placeholder="Username" />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Control type="email" placeholder="Email address" />
            </Form.Group>
            <Form.Group
              controlId="formPassword"
              className="password-container mb-3 position-relative"
            >
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
              />
              <span
                className="eye-icon position-absolute"
                style={{
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </span>
            </Form.Group>
            <Form.Group
              controlId="formCheckbox"
              className="checkbox-container mb-4"
            >
              <Form.Check
                type="checkbox"
                label={
                  <>
                    I agree with <a href="#privacy-policy">Privacy Policy</a>{" "}
                    and <a href="#terms-of-use">Terms of Use</a>
                  </>
                }
              />
            </Form.Group>
            <Button type="submit" variant="dark" className="w-100">
              Sign Up
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default SignUp;
