import React, { useState } from "react";
import "./PromoBanner.css";

import ticketPercent from "../images/ticket-percent.png";

import { BiRightArrowAlt, BiX } from "react-icons/bi";

function PromoBanner() {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="promo-banner">
      <div className="con col-md-3 d-flex align-items-center m-auto">
        <img src={ticketPercent} alt="ticket" />
        <p>
          30% off storewide — Limited time!{" "}
          <a href="#shop">
            Shop Now <BiRightArrowAlt />
          </a>
        </p>
      </div>
      <button className="promo-close-btn" onClick={handleClose}>
        <BiX size={24} color="black" />
      </button>
    </div>
  );
}

export default PromoBanner;
